import React, { useRef } from "react";
import html2canvas from "html2canvas";

const Login = () => {
  const canvasRef = useRef();

  const generateSignature = async () => {
    const element = canvasRef.current;
    const canvas = await html2canvas(element);
    const base64Image = canvas.toDataURL("image/png");
    // console.log(base64Image);
  };

  return (
    <div>
      <div
        ref={canvasRef}
        style={{
          fontFamily: "Cursive",
          fontSize: "30px",
          color: "black",
          padding: "10px",
          display: "inline-block",
          border: "1px solid black",
        }}
      >
        AKshay Thota
      </div>
      <button onClick={generateSignature}>Generate Signature</button>
    </div>
  );
};

const { createCanvas } = require("canvas");

function generateBase64Signature(name) {
  const width = 500;
  const height = 200;

  // Create a canvas
  const canvas = createCanvas(width, height);
  const ctx = canvas.getContext("2d");

  // Set the styles for the signature text
  ctx.fillStyle = "#FFFFFF"; // Background color
  ctx.fillRect(0, 0, width, height); // Fill the background
  ctx.font = "30px Cursive"; // Font size and family
  ctx.fillStyle = "#000000"; // Text color
  ctx.textAlign = "center"; // Center align the text
  ctx.textBaseline = "middle"; // Vertically align the text

  // Draw the name in the center of the canvas
  ctx.fillText(name, width / 2, height / 2);

  // Convert the canvas to a Base64 image
  const base64Image = canvas.toDataURL("image/png");

  return base64Image;
}

// Example usage:
const name = "Akshay Thota";
const base64Signature = generateBase64Signature(name);
console.log(base64Signature);

export default Login;
